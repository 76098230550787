import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['']);

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./address-book/addressbook.component').then((m) => m.AddressBookComponent),
  },
  {
    path: 'labels',
    loadComponent: () => import('./labels/labels.component').then((m) => m.LabelsComponent),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToHome },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
