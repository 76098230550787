import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { M as MatPseudoCheckbox } from './pseudo-checkbox-4a60d45e.mjs';
import { M as MatCommonModule } from './common-module-a39ee957.mjs';
class MatPseudoCheckboxModule {
  static ɵfac = function MatPseudoCheckboxModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MatPseudoCheckboxModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MatPseudoCheckboxModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [MatCommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatPseudoCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [MatCommonModule, MatPseudoCheckbox],
      exports: [MatPseudoCheckbox]
    }]
  }], null, null);
})();
export { MatPseudoCheckboxModule as M };
