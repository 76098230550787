const MAC_ENTER = 3;
const BACKSPACE = 8;
const TAB = 9;
const NUM_CENTER = 12;
const ENTER = 13;
const SHIFT = 16;
const CONTROL = 17;
const ALT = 18;
const PAUSE = 19;
const CAPS_LOCK = 20;
const ESCAPE = 27;
const SPACE = 32;
const PAGE_UP = 33;
const PAGE_DOWN = 34;
const END = 35;
const HOME = 36;
const LEFT_ARROW = 37;
const UP_ARROW = 38;
const RIGHT_ARROW = 39;
const DOWN_ARROW = 40;
const PLUS_SIGN = 43;
const PRINT_SCREEN = 44;
const INSERT = 45;
const DELETE = 46;
const ZERO = 48;
const ONE = 49;
const TWO = 50;
const THREE = 51;
const FOUR = 52;
const FIVE = 53;
const SIX = 54;
const SEVEN = 55;
const EIGHT = 56;
const NINE = 57;
const FF_SEMICOLON = 59; // Firefox (Gecko) fires this for semicolon instead of 186
const FF_EQUALS = 61; // Firefox (Gecko) fires this for equals instead of 187
const QUESTION_MARK = 63;
const AT_SIGN = 64;
const A = 65;
const B = 66;
const C = 67;
const D = 68;
const E = 69;
const F = 70;
const G = 71;
const H = 72;
const I = 73;
const J = 74;
const K = 75;
const L = 76;
const M = 77;
const N = 78;
const O = 79;
const P = 80;
const Q = 81;
const R = 82;
const S = 83;
const T = 84;
const U = 85;
const V = 86;
const W = 87;
const X = 88;
const Y = 89;
const Z = 90;
const META = 91; // WIN_KEY_LEFT
const MAC_WK_CMD_LEFT = 91;
const MAC_WK_CMD_RIGHT = 93;
const CONTEXT_MENU = 93;
const NUMPAD_ZERO = 96;
const NUMPAD_ONE = 97;
const NUMPAD_TWO = 98;
const NUMPAD_THREE = 99;
const NUMPAD_FOUR = 100;
const NUMPAD_FIVE = 101;
const NUMPAD_SIX = 102;
const NUMPAD_SEVEN = 103;
const NUMPAD_EIGHT = 104;
const NUMPAD_NINE = 105;
const NUMPAD_MULTIPLY = 106;
const NUMPAD_PLUS = 107;
const NUMPAD_MINUS = 109;
const NUMPAD_PERIOD = 110;
const NUMPAD_DIVIDE = 111;
const F1 = 112;
const F2 = 113;
const F3 = 114;
const F4 = 115;
const F5 = 116;
const F6 = 117;
const F7 = 118;
const F8 = 119;
const F9 = 120;
const F10 = 121;
const F11 = 122;
const F12 = 123;
const NUM_LOCK = 144;
const SCROLL_LOCK = 145;
const FIRST_MEDIA = 166;
const FF_MINUS = 173;
const MUTE = 173; // Firefox (Gecko) fires 181 for MUTE
const VOLUME_DOWN = 174; // Firefox (Gecko) fires 182 for VOLUME_DOWN
const VOLUME_UP = 175; // Firefox (Gecko) fires 183 for VOLUME_UP
const FF_MUTE = 181;
const FF_VOLUME_DOWN = 182;
const LAST_MEDIA = 183;
const FF_VOLUME_UP = 183;
const SEMICOLON = 186; // Firefox (Gecko) fires 59 for SEMICOLON
const EQUALS = 187; // Firefox (Gecko) fires 61 for EQUALS
const COMMA = 188;
const DASH = 189; // Firefox (Gecko) fires 173 for DASH/MINUS
const PERIOD = 190;
const SLASH = 191;
const APOSTROPHE = 192;
const TILDE = 192;
const OPEN_SQUARE_BRACKET = 219;
const BACKSLASH = 220;
const CLOSE_SQUARE_BRACKET = 221;
const SINGLE_QUOTE = 222;
const MAC_META = 224;
export { M as $, ALT as A, BACKSPACE as B, CONTROL as C, DOWN_ARROW as D, END as E, FOUR as F, E as G, HOME as H, INSERT as I, F as J, G as K, LEFT_ARROW as L, MAC_META as M, NINE as N, ONE as O, PAGE_DOWN as P, QUESTION_MARK as Q, RIGHT_ARROW as R, SHIFT as S, TAB as T, UP_ARROW as U, H as V, I as W, J as X, K as Y, Z, L as _, META as a, CLOSE_SQUARE_BRACKET as a$, N as a0, O as a1, P as a2, Q as a3, R as a4, S as a5, T as a6, U as a7, V as a8, W as a9, F7 as aA, F8 as aB, F9 as aC, F10 as aD, F11 as aE, F12 as aF, NUM_LOCK as aG, SCROLL_LOCK as aH, FIRST_MEDIA as aI, FF_MINUS as aJ, MUTE as aK, VOLUME_DOWN as aL, VOLUME_UP as aM, FF_MUTE as aN, FF_VOLUME_DOWN as aO, LAST_MEDIA as aP, FF_VOLUME_UP as aQ, SEMICOLON as aR, EQUALS as aS, COMMA as aT, DASH as aU, PERIOD as aV, SLASH as aW, APOSTROPHE as aX, TILDE as aY, OPEN_SQUARE_BRACKET as aZ, BACKSLASH as a_, X as aa, Y as ab, MAC_WK_CMD_LEFT as ac, MAC_WK_CMD_RIGHT as ad, CONTEXT_MENU as ae, NUMPAD_ZERO as af, NUMPAD_ONE as ag, NUMPAD_TWO as ah, NUMPAD_THREE as ai, NUMPAD_FOUR as aj, NUMPAD_FIVE as ak, NUMPAD_SIX as al, NUMPAD_SEVEN as am, NUMPAD_EIGHT as an, NUMPAD_NINE as ao, NUMPAD_MULTIPLY as ap, NUMPAD_PLUS as aq, NUMPAD_MINUS as ar, NUMPAD_PERIOD as as, NUMPAD_DIVIDE as at, F1 as au, F2 as av, F3 as aw, F4 as ax, F5 as ay, F6 as az, PAGE_UP as b, SINGLE_QUOTE as b0, A as c, ZERO as d, ESCAPE as e, MAC_ENTER as f, NUM_CENTER as g, ENTER as h, PAUSE as i, CAPS_LOCK as j, SPACE as k, PLUS_SIGN as l, PRINT_SCREEN as m, DELETE as n, TWO as o, THREE as p, FIVE as q, SIX as r, SEVEN as s, EIGHT as t, FF_SEMICOLON as u, FF_EQUALS as v, AT_SIGN as w, B as x, C as y, D as z };
