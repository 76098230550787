import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

/**
 * Component used to load the .cdk-visually-hidden styles.
 * @docs-private
 */
class _VisuallyHiddenLoader {
  static ɵfac = function _VisuallyHiddenLoader_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || _VisuallyHiddenLoader)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: _VisuallyHiddenLoader,
    selectors: [["ng-component"]],
    exportAs: ["cdkVisuallyHidden"],
    decls: 0,
    vars: 0,
    template: function _VisuallyHiddenLoader_Template(rf, ctx) {},
    styles: [".cdk-visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;white-space:nowrap;outline:0;-webkit-appearance:none;-moz-appearance:none;left:0}[dir=rtl] .cdk-visually-hidden{left:auto;right:0}"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(_VisuallyHiddenLoader, [{
    type: Component,
    args: [{
      exportAs: 'cdkVisuallyHidden',
      encapsulation: ViewEncapsulation.None,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".cdk-visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;white-space:nowrap;outline:0;-webkit-appearance:none;-moz-appearance:none;left:0}[dir=rtl] .cdk-visually-hidden{left:auto;right:0}"]
    }]
  }], null, null);
})();
export { _VisuallyHiddenLoader as _ };
