import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { M as MatRippleModule } from './index-a36e3c7c.mjs';
import { M as MatCommonModule } from './common-module-a39ee957.mjs';
import { a as MatOption, e as MatOptgroup } from './option-cb353f7a.mjs';
import { M as MatPseudoCheckboxModule } from './pseudo-checkbox-module-31a58606.mjs';
class MatOptionModule {
  static ɵfac = function MatOptionModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MatOptionModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MatOptionModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [MatRippleModule, MatCommonModule, MatPseudoCheckboxModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatOptionModule, [{
    type: NgModule,
    args: [{
      imports: [MatRippleModule, MatCommonModule, MatPseudoCheckboxModule, MatOption, MatOptgroup],
      exports: [MatOption, MatOptgroup]
    }]
  }], null, null);
})();
export { MatOptionModule as M };
